import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid2,
  Typography,
} from "@mui/material";

import spacer from "../assets/wave-spacer.svg";

export default function Projects() {
  return (
    <>
      <Grid2
        container
        display={"flex"}
        direction="column"
        alignItems={"center"}
        minHeight={"90vh"}
        height={"auto"}
        className=" spacer-image"
      >
        <div className="title">Projects</div>
        <section style={{ paddingBottom: "10%" }}>
          <Grid2
            container
            display={"flex"}
            maxWidth="100%"
            alignItems="center"
            justifyContent="center"
          >
            <div className="projectCard">
              <Card sx={{ backgroundColor: "#CFE8FC" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Manifold Research Group Website
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    The Manifold Research group is an open source R&D Lab
                    interested in building the next generation of intelligent
                    Systems. Come check it out!
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    href="https://www.manifoldrg.com"
                    target="_blank"
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </div>
            <div className="projectCard">
              <Card sx={{ backgroundColor: "#CFE8FC" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Finacial Foliage
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    This project is an expense tracker that I made as i took in
                    interest in trying out Shadcn ui charts as well as supabase
                    as the backend.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    href="https://main.d32rtg0g0pzbyx.amplifyapp.com/"
                    target="_blank"
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </div>
          </Grid2>
          {/* <Grid2
            container
            display={"flex"}
            maxWidth="100%"
            alignItems="center"
            justifyContent="space-around"
          >
            <div className="projectCard">
              <Card sx={{ backgroundColor: "#CFE8FC" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Manifold Research Group Website
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    The Manifold Research group is an open source R&D Lab
                    interested in building the next generation of intelligent
                    Systems. Come check it out!
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    href="https://www.manifoldrg.com"
                    target="_blank"
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </div>
          </Grid2> */}
        </section>
      </Grid2>
    </>
  );
}
