import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4FADF7", // Light Blue (Wave and other primary elements)
    },
    secondary: {
      main: "#005F73", // Teal (Header background gradient part)
    },
    background: {
      default: "#CFE8FC", // Light blue background (body)
      paper: "#ffffff", // White or light background for cards
    },
    text: {
      primary: "#000000", // Black or dark text
      white: "#FFFFFF", // Black or dark text
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      //   fontSize: "3rem",
      //   fontWeight: "bold",
    },
    h2: {
      //   fontSize: "2rem",
      //   fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
    },
  },

  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#FFFFFF", // Override the default color of MenuItem text to white
        },
      },
    },
    //   MuiButton: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: "20px", // Rounded buttons or icons
    //       },
    //     },
    //   },
  },
});

export default theme;
