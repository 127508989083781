import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Grid2 } from "@mui/material";

export default function TimelineComponent() {
  return (
    <Grid2
      container
      display={"flex"}
      direction="row"
      alignItems={"center"}
      minHeight={"30vh"}
      height={"auto"}
    >
      <Timeline
        position="right"
        sx={{
          marginBottom: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{}}>
            <div className=" timelineCard ">
              <Grid2
                container // Add this
                display={"flex"}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid2
                  display={"flex"}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                >
                  <h2 className="noMargin">Web Developer</h2>
                </Grid2>
                <p> Manifold Research Group</p>
                {/* <p> 2023</p> */}
              </Grid2>
              <p>Technologies: Ghost.io, Handlebars.js</p>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className=" timelineCard ">
              <Grid2
                container
                display={"flex"}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid2
                  display={"flex"}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                >
                  <h2 className="noMargin">Full-Stack Developer</h2>
                </Grid2>
                <p>Right Angle Engineering</p>
                {/* <p> 2023 - 2024</p> */}
              </Grid2>
              <p>Technologies: React, Material-UI, Django</p>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>
            {" "}
            <div className=" timelineCard ">
              <Grid2
                container
                display={"flex"}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid2
                  display={"flex"}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                >
                  <h2 className="noMargin">B.S. Computer Science</h2>
                </Grid2>
                <p> Brigham Young University </p>
                {/* <p> 2023</p> */}
              </Grid2>
              <p>Languages: Javascript, Python, Java, C++, C</p>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Timeline
        position="right"
        sx={{
          marginTop: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="timelineCard">
              <Grid2
                container // Add this
                display={"flex"}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid2
                  display={"flex"}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                >
                  <h2 className="noMargin">Frontend Developer</h2>
                </Grid2>
                <p>BYU J. Reuben Clark Law School</p>
                {/* <p> 2021 - 2023</p> */}
              </Grid2>
              <p>Technologies: React, bootstrap, AWS, NodeJs, MySQL</p>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className=" timelineCard ">
              <Grid2
                container // Add this
                display={"flex"}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid2
                  display={"flex"}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={0}
                >
                  <h2 className="noMargin">Research Assistant</h2>
                </Grid2>
                <p> BYU Social Tech and Privacy Lab (STaPL) </p>
                {/* <p> 2021 - 2022</p> */}
              </Grid2>
              <p>Technologies: VueJs, bootstrap, Figma</p>
            </div>
          </TimelineContent>
        </TimelineItem>
        {/* <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>Sleep</TimelineContent>
        </TimelineItem> */}
      </Timeline>
    </Grid2>
  );
}

{
  /* <Timeline position="left">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div
            className=" timelineCard "
      
            // style={{  padding: "3%",
            //         margin: "3%",
            //         background-color: "white",
            //         border-radius: "25px",}}
          >
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">Web Developer</h2>
              </Grid2>
              <p> Manifold Research Group</p>
            </Grid2>
            <p>Technologies: Ghost.io, Handlebars.js</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className=" timelineCard ">
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">Full-Stack Developer</h2>
              </Grid2>
              <p>Right Angle Engineering</p>
            </Grid2>
            <p>Technologies: React, Material-UI, Django</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>
          {" "}
          <div className=" timelineCard ">
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">B.S. Computer Science</h2>
              </Grid2>
              <p> Brigham Young University </p>
            </Grid2>
            <p>Languages: Javascript, Python, Java, C++, C</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="timelineCard">
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">Frontend Developer</h2>
              </Grid2>
              <p>BYU J. Reuben Clark Law School</p>
            </Grid2>
            <p>Technologies: React, bootstrap, AWS, NodeJs, MySQL</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className=" timelineCard ">
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">Research Assistant</h2>
              </Grid2>
              <p> BYU Social Tech and Privacy Lab (STaPL) </p>
            </Grid2>
            <p>Technologies: VueJs, bootstrap, Figma</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className=" timelineCard ">
            <Grid2
              container // Add this
              display={"flex"}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={0}
            >
              <Grid2
                display={"flex"}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={0}
              >
                <h2 className="noMargin">Research Assistant</h2>
                <p> BYU Distribution Systems Research </p>
              </Grid2>
              <p> 2020</p>
            </Grid2>
            <p>Technologies: Javascript, Typescript, Markdown</p>
          </div>
        </TimelineContent>
      </TimelineItem>
      </Timeline> */
}
