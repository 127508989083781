import React, { useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Wave from "react-wavify";

export default function Header({ handlePageChange }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Media query to check if the screen width is less than 600px (mobile)
  const isMobile = useMediaQuery("(max-width:600px)");

  const lastPartUrl = window.location.pathname.split("/").filter(Boolean).pop();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerAndPageChange = (page) => {
    handlePageChange(page);
    setDrawerOpen(false); // Close the drawer after clicking the item
  };

  const drawerItems = (
    <List>
      <ListItem button onClick={() => handleDrawerAndPageChange("home")}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button onClick={() => handleDrawerAndPageChange("about")}>
        <ListItemText primary="About" />
      </ListItem>
      <ListItem button onClick={() => handleDrawerAndPageChange("projects")}>
        <ListItemText primary="Projects" />
      </ListItem>
      <ListItem button onClick={() => handleDrawerAndPageChange("contact")}>
        <ListItemText primary="Contact" />
      </ListItem>
    </List>
  );

  return (
    <>
      <section
        style={{
          backgroundColor:
            lastPartUrl === "about" || lastPartUrl === "contact"
              ? "#CFE8FC"
              : "",
        }}
      >
        <Wave
          fill="#4FADF7"
          className="wave-motion"
          options={{
            height: 10,
            amplitude: 25,
            speed: 0.1,
            points: 4,
          }}
        />
        <Wave
          fill="#00A9C8"
          className="wave-motion-2"
          options={{
            height: 10,
            amplitude: 20,
            speed: 0.1,
            points: 3,
          }}
        />
        <Wave
          fill="#00A0A0"
          className="wave-motion-3"
          options={{
            height: 10,
            amplitude: 15,
            speed: 0.1,
            points: 2,
          }}
        />

        <AppBar
          sx={{
            background: "transparent",
            boxShadow: "none",
            position: "absolute",
            color: "white", // Ensures the text and icons are white
          }}
        >
          <Toolbar>
            <IconButton href="/" edge="start">
              <div className="circle">MB</div>
            </IconButton>

            {/* Conditionally render based on screen size */}
            {isMobile ? (
              <>
                {/* Hamburger icon for mobile */}
                <IconButton
                  edge="end"
                  color="inherit" // Inherit white color from the AppBar
                  onClick={handleDrawerToggle}
                  aria-label="menu"
                  sx={{ marginLeft: "auto" }} // Move icon to the right side
                >
                  <MenuIcon />
                </IconButton>

                {/* Drawer for mobile navigation */}
                <Drawer
                  anchor="top"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                >
                  {drawerItems}
                </Drawer>
              </>
            ) : (
              <>
                {/* Regular menu items for larger screens */}
                <MenuItem onClick={() => handlePageChange("home")}>
                  Home
                </MenuItem>
                <MenuItem onClick={() => handlePageChange("about")}>
                  About
                </MenuItem>
                <MenuItem onClick={() => handlePageChange("projects")}>
                  Projects
                </MenuItem>
                <MenuItem onClick={() => handlePageChange("contact")}>
                  Contact
                </MenuItem>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Box
          component="div"
          sx={{
            maxWidth: "100%",
            height: "10vh",
          }}
        />
      </section>
    </>
  );
}
