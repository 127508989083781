import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const formRef = useRef(); // Reference to the form
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  }); // Form data
  const [isLoading, setIsLoading] = useState(false); // Loading state
  // const [alert, setAlert] = useState({ show: false, message: "", type: "" }); // Alert state

  // Event handler functions
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Using EmailJS for sending email
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Access environment variable
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Access environment variable
        formRef.current, // Pass the form reference here
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          // setAlert({
          //   show: true,
          //   message: "Message sent successfully!",
          //   type: "success",
          // });
          setIsLoading(false);
          setForm({ name: "", email: "", message: "" }); // Reset form
        },
        (error) => {
          console.log("FAILED...", error.text);
          // setAlert({
          //   show: true,
          //   message: "An error occurred. Please try again later.",
          //   type: "error",
          // });
          setIsLoading(false);
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <Grid2
        height={"60vh"}
        style={{
          // marginBottom: "-25%",
          backgroundColor: "#CFE8FC",
          // background: "linear-gradient(to bottom, #CFE8FC, #84C3F9)",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 7,
            mb: 7,
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 4 }}>
            Get in Touch
          </Typography>

          <Box
            component="form"
            ref={formRef} // Reference the form element
            onSubmit={handleSubmit} // Use onSubmit here
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 3,
              width: "50%",
            }}
          >
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              required
              value={form.name}
              onChange={handleChange}
              fullWidth
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              required
              value={form.email}
              onChange={handleChange}
              fullWidth
              sx={{ backgroundColor: "white" }}
            />

            <TextField
              label="Message"
              name="message"
              multiline
              rows={4}
              variant="outlined"
              value={form.message}
              onChange={handleChange}
              fullWidth
              sx={{ backgroundColor: "white" }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{ mt: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Grid2>
      <Grid2
        height={"40vh"}
        className="spacer-image-2"
        // display={"flex"}
        // justifyContent={"center"}
        sx={{ marginTop: "-5%" }}
      ></Grid2>
    </>
  );
}
