import React from "react";
import { Grid2 } from "@mui/material";
import Lottie from "lottie-react";
// import animationData from "../assets/jellyfish.json"; // Update this path
import animationData from "../assets/laptopMan.json"; // Update this path

export default function Home() {
  return (
    <Grid2
      container
      maxWidth="100%"
      height="90vh"
      direction="column"
      alignItems="center"
      className="spacer-image"
    >
      <div className="nameTitle">Miles Batey</div>
      <Lottie
        animationData={animationData}
        className="personAnimation"
        loop={true}
        autoplay={true}
        style={{
          overflow: "hidden",
        }}
      />
    </Grid2>
  );
}
