import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Wave from "react-wavify";
import theme from "./theme";
import { useMediaQuery } from "@mui/material";

function App() {
  // console.log(window.location.pathname)

  const [currentPage, setCurrentPage] = useState("");
  const [isCurtainVisible, setIsCurtainVisible] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handlePageChange = (page) => {
    setIsCurtainVisible(true);

    setTimeout(() => {
      setCurrentPage(page);
      setTimeout(() => {
        if (page === "home") {
          window.history.pushState({}, "", "/");
        } else if (page !== undefined) {
          window.history.pushState({}, "", `/${page}/`);
        }
        setIsCurtainVisible(false);
      }, 1000);
    }, 1500);
  };

  useEffect(() => {
    const lastPartUrl = window.location.pathname
      .split("/")
      .filter(Boolean)
      .pop();

    // setIsCurtainVisible(true);
    // handlePageChange(lastPartUrl);
    if (lastPartUrl === undefined || lastPartUrl === "") {
      setCurrentPage("home");
    } else {
      setCurrentPage(lastPartUrl);
    }
  }, []);

  const renderPage = () => {
    switch (currentPage) {
      case "home" || "":
        return <Home />;
      case "about":
        return <About />;
      case "projects":
        return <Projects />;
      case "contact":
        return <Contact />;
      default:
        return;
      // return <Home />;
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={`curtain-overlay ${isCurtainVisible ? "visible" : ""}`}>
          <div className="top-section"></div>
          <div className="bottom-section">
            <Wave
              fill={theme.palette.primary.main}
              className="wave-motion"
              options={{
                height: 30,
                amplitude: isMobile ? 15 : 35,
                speed: 0.3,
                points: 4,
              }}
            />
          </div>
        </div>

        <div className={`dissolve-effect ${isCurtainVisible ? "hidden" : ""}`}>
          <Header handlePageChange={handlePageChange} />
          {renderPage()}
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
